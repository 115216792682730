import jwt_decode from 'jwt-decode';

const numberArray = new Uint32Array(10);

export function test() {
	return null;
}

export const cookieNameGenerator = (name) => {
	if (process.env.REACT_APP_ENV === 'production') {
		return name;
	}
	return `${process.env.REACT_APP_ENV}-${name}`;
};

export const convertToTextNumber = (angka) => {
	return new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(angka);
};

export const getRequester = () => {
	const acToken = JSON.parse(localStorage.getItem('accessToken'));
	const position = JSON.parse(localStorage.getItem('position'));
	const photo = JSON.parse(localStorage.getItem('photo'));
	const decodedToken = jwt_decode(acToken.accessToken);
	const { details, username } = decodedToken;

	const result = {
		username,
		email: details.hris_org_tree?.current_person?.email,
		department: position?.department_name,
		department_code: position?.department_code,
		person_name: details.hris_org_tree?.current_person?.person_name,
		position_name: position?.position_name,
		position_code: position?.position_code,
		position,
		photo,
	};

	if (details?.version) result.version = details.version;

	return result;
};

export const getRoles = () => {
	const roles = JSON.parse(localStorage.getItem('roles'));

	return { roles };
};

export const getaActiveRoles = () => {
	const roles = JSON.parse(localStorage.getItem('active_role')) ? '' : '';
	return {
		default_role_name: roles,
		default_role_code: roles,
		default_segment_code: roles,
		default_segment_name: roles,
	};
};

export const getListRoles = () => {
	const roles = JSON.parse(localStorage.getItem('list_roles'));
	const selected_roles = JSON.parse(localStorage.getItem('roles'));
	const selected_position = JSON.parse(localStorage.getItem('position'));
	return {
		roles: roles.map((r) => ({
			...r,
			is_selected:
				r.role_name === selected_roles &&
				r.position_code === selected_position.position_code,
		})),
	};
};

export const getActiveRoles = () => {
	const roles = JSON.parse(localStorage.getItem('active_role')) ? '' : '';
	return {
		default_role_name: roles,
		default_role_code: roles,
		default_segment_code: roles,
		default_segment_name: roles,
	};
};

export const getRouting = (data) => {
	const rawRoute = Object.keys(data)
		.map((item) => {
			if (data[item].subMenu) {
				return Object.keys(data[item].subMenu).map((sb) => {
					return {
						path: data[item].subMenu[sb].path,
						element: data[item].subMenu[sb].element,
					};
				});
			}
			return null;
		})
		.filter((e) => e);
	const merge = [].concat(...rawRoute);
	return merge;
};

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const convertToRupiah = (angka) => {
	let rupiah = '';
	const angkarev = angka.toString().split('').reverse().join('');
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < angkarev.length; i++)
		if (i % 3 === 0) rupiah += `${angkarev.substr(i, 3)}.`;
	return `Rp. ${rupiah
		.split('', rupiah.length - 1)
		.reverse()
		.join('')}`;
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(crypto.getRandomValues(numberArray) * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	// eslint-disable-next-line func-names
	return function (...args) {
		const context = this;
		return new Promise((resolve) => {
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				timeout = null;
				resolve(func.apply(context, args));
			}, wait);
		});
	};
};

export const isEmptyObject = (obj) => {
	return obj == null || !Object.keys(obj).length;
};

export const isIsoDate = (str) => {
	if (str.includes('T')) {
		// this is datetime
		if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return false;
	} else if (!/\d{4}-\d{2}-\d{2}/.test(str)) return false;
	const d = new Date(str);
	return d instanceof Date && !Number.isNaN(d.getTime()) && d.toISOString().startsWith(str); // valid date
};
